import { FC, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  useTheme,
  TextField,
  Button,
  Stack,
  Paper,
  IconButton,
  styled,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApp } from "../../../hooks/useApp";
import { LoginTypes } from "../../../types/Types";
import Footer from "../../../components/Footer";
import { MappRiskLogo } from "../../../components/svgicons/SvgIcons";
import {
  SVloginCodeRequest,
  SVloginRequest,
} from "../../../services/security/SecurityService";
import { properties } from "../../../utils/Properties_es";
import MessageManager from "../../../components/utils/MessageManager";
import Loading from "../../../components/utils/Loading";
import { CONSTANT } from "../../../utils/Constants";
import ButtonPrimary from "../../../components/ButtonPrimary";
import PaperSessions from "../../../components/PaperSessions";
import { useData } from "../../../hooks/useData";
import { getItem, setItem } from "../../../utils/LocalStorageManager";
import { findByIdCompany } from "../../../services/company/CompanyService";

const Login: FC = () => {
  const {
    isLoading,
    setLoading,
    setErrorMsg,
    successMsg,
    setSuccessMsg,
    errorMsg,
    loginProvider,
    resetErrorMsg,
    resetSuccessMsg,
    authInfo,
  } = useApp();
  const {
    setCompanyData,
    setSubCompanyData,
    companyIndexDB,
    handleLocalCompaniesAndSubcompanies,
    handleFetchDataSubCompany,
  } = useData();
  const [showCode, setShowCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [companyInfo, setCompanyInfo] = useState<any>(null);
  const [subcompanyInfo, setsubcompanyInfo] = useState<any>(null);

  const validation = Yup.object().shape({
    username: Yup.string()
      .required("Email es requerido")
      .email("Email es invalido"),
    password: Yup.string().required("Contraseña es requerida"),
    code: Yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<LoginTypes>({
    resolver: yupResolver(validation),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [isAdmin, setIsAdmin] = useState<any>(undefined);

  // Use useEffect to update isAdmin when authInfo changes
  useEffect(() => {
    if (authInfo) {
      const adminStatus = authInfo?.userRole?.authorityRoles.some(
        (authority: any) => authority.authority.code === "ADMIN:WRITE"
      );

      setIsAdmin(adminStatus);
    }
  }, [authInfo]);

  //console.log(isAdmin);
  //console.log(authInfo);

  const onSubmit = async (data: any) => {
    try {
      setLoading && setLoading(true);

      // Obtener código de dos factores
      if (!showCode) {
        const request: any = await SVloginCodeRequest(data);
        setLoading && setLoading(false);
        setShowCode(true);
        setSuccessMsg && setSuccessMsg(request?.message);

        // Asignar companyInfo y subcompanyInfo desde la respuesta de request
        setCompanyInfo(request?.companyInfo);
        setsubcompanyInfo(request?.subcompanyInfo);
        setCompanyData && setCompanyData(request?.companyInfo[0]);
        setSubCompanyData && setSubCompanyData(request?.subcompanyInfo[0]);
        // Aquí puedes optar por retornar para que el flujo de autenticación no continúe si solo necesitas esto.
        return;
      }

      // Autenticar al usuario
      const loginData = await SVloginRequest(data);

      // Combina companyInfo y subcompanyInfo con loginData
      const extendedLoginData = {
        ...loginData,
        companyInfo,
        subcompanyInfo,
      };

      await loginProvider(extendedLoginData);

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg &&
        setErrorMsg(
          error.message ?? properties.com_mapprisk_label_login_general_error
        );
    }
  };

  return (
    <>
      <PaperSessions
        to="recover-password"
        textLink="¿Olvidaste tu contraseña?"
        titleForm="Iniciar sesión"
      >
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} justifyContent="center">
              <Controller
                name={"username"}
                control={control}
                render={() => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Correo electrónico"
                    type="email"
                    variant="standard"
                    autoComplete="username"
                    disabled={showCode}
                    {...register("username")}
                    error={errors.username && Boolean(errors.username)}
                    helperText={errors.username && errors.username.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <Controller
                name={"password"}
                control={control}
                render={() => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Contraseña"
                    type={showPassword ? "text" : "password"}
                    variant="standard"
                    disabled={showCode}
                    {...register("password")}
                    error={errors.password && Boolean(errors.password)}
                    helperText={errors.password && errors.password.message}
                    sx={{
                      "& .MuiInput-root:before": {
                        borderBottomColor: "#8bc8bc!important",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} hidden={!showCode} justifyContent="center">
              <Controller
                name={"code"}
                control={control}
                render={() => (
                  <TextField
                    fullWidth
                    label="Código de validación"
                    size="small"
                    type="text"
                    variant="standard"
                    {...register("code")}
                    error={!!errors.code}
                    helperText={!!errors.code && errors.code?.message}
                    sx={{
                      "& .MuiInput-root:before": {
                        borderBottomColor: "#8bc8bc!important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} justifyContent="center" display="flex">
              <ButtonPrimary type="submit" onClick={handleSubmit(onSubmit)}>
                Iniciar sesión
              </ButtonPrimary>
            </Grid>
          </Grid>
        </form>
      </PaperSessions>
    </>
  );
};

export default Login;
